/* eslint-disable no-console */
/* global smily, bootstrap */

class RentalsWishList extends HTMLElement {
  constructor() {
    super();

    const modalId = 'rentalsWishListModal';
    this.localStorageItemName = 'RentalsWishListIds';
    this.modalTemplate = smily.templates.modal({
      id: modalId,
      dialogClass: 'modal-dialog-centered modal-dialog-scrollable modal-xl',
      title: smily.website.t.rentalsWishlist.title,
      contentClass: this.getAttribute('data-content-class'),
    });

    const modal = document.getElementById(modalId);
    if (modal) {
      this.modal = modal;
      this.bsModal = bootstrap.Modal.getInstance(this.modal);
    } else {
      document.body.insertAdjacentHTML('beforeend', this.modalTemplate);
      this.modal = document.getElementById(modalId);
      this.bsModal = smily.modal(this.modal);
      this.modal.addEventListener('show.bs.modal', this.fetchRentals.bind(this));
    }

    this.wrapper = this.modal.querySelector('[data-content]');

    this.toggleVisible();
    this.addEventListener('updated', this.toggleVisible);
    this.buttonRentalsCount();
    this.addEventListener('updated', this.buttonRentalsCount);
    this.addEventListener('click', () => this.bsModal.show());
  }

  get rentalsIds() {
    return JSON.parse(window.localStorage.getItem(this.localStorageItemName)) ?? [];
  }

  set rentalsIds(ids) {
    if (ids) {
      window.localStorage.setItem(this.localStorageItemName, JSON.stringify(ids));
    } else {
      window.localStorage.removeItem(this.localStorageItemName);
    }

    this.dispatchEvent(new Event('updated'));
  }

  fetchRentals() {
    this.wrapper.innerHTML = '';

    if (this.rentalsIds.length) {
      this.loadRentalPages({ 'ids[]': this.rentalsIds });
    } else {
      this.wrapper = smily.website.t.noResult;
    }
  }

  async loadRentalPages(ids, page = 1) {
    // add cards placeholders
    this.wrapper.insertAdjacentHTML('beforeend', smily.RentalsCardsPlacholders(4));

    const data = await smily.rentalsList({ ...ids, ...page });

    // remove cards placeholders
    this.wrapper.querySelectorAll('.card-placeholder').forEach((element) => element.remove());

    this.wrapper.insertAdjacentHTML('beforeend', smily.RentalsCardsList(data.rentals, 'rentalsWishlist'));

    const { pagination } = data.meta;
    if (pagination.page !== Math.ceil(pagination.total_count / pagination.per_page)) {
      this.loadRentalPages(ids, page + 1);
    }
  }

  toggleVisible() {
    this.classList.toggle('d-none', !this.rentalsIds.length);
  }

  buttonRentalsCount() {
    this.querySelector('[role="note"]').textContent = this.rentalsIds.length;
  }
}

customElements.define('rentals-wishlist', RentalsWishList);

class RentalsWishListAdd extends HTMLElement {
  constructor() {
    super();

    this.wishlist = document.querySelector('rentals-wishlist');
  }

  get rentalId() {
    return this.getAttribute('rental-id');
  }

  get isInWishList() {
    return this.wishlist.rentalsIds.includes(this.rentalId);
  }

  connectedCallback() {
    if (this.rentalId && this.wishlist) {
      this.toggleActive();
      this.wishlist.addEventListener('updated', this.toggleActive.bind(this));
      this.addEventListener('click', () => {
        this.storeItem();
        this.blur();
      });
    }
  }

  toggleActive() {
    this.classList.toggle('active', this.isInWishList);
  }

  storeItem() {
    const { rentalId, isInWishList } = this;
    const { rentalName, rentalBsId } = this.dataset;
    const { rentalsIds } = this.wishlist;

    if (isInWishList) {
      const index = rentalsIds.indexOf(rentalId);
      delete rentalsIds[index];
    } else {
      rentalsIds.push(rentalId);
    }

    this.wishlist.rentalsIds = rentalsIds.filter(Boolean);
    this.showToast(isInWishList);

    const eventLabel = isInWishList ? 'remove_from_wishlist' : 'add_to_wishlist';
    smily.trackers.ga('event', eventLabel, {
      items: [{
        item_id: `#${rentalBsId}`,
        item_name: `${rentalName}`,
        item_category: 'product',
      }],
    });
  }

  showToast(isInWishList) {
    const { added, removed } = smily.website.t.rentalsWishlist;
    const toastTemplate = `
      <div class="toast align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">${isInWishList ? removed : added}</div>
          <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    `;

    const toastContainer = document.querySelector('.toast-container');
    toastContainer.insertAdjacentHTML('afterbegin', toastTemplate);
    new bootstrap.Toast(toastContainer.firstElementChild).show();
  }
}

customElements.define('rentals-wishlist-add', RentalsWishListAdd);
