/* global smily */

class WebsiteForm extends HTMLElement {
  constructor() {
    super();

    this.timeout = null;
    this.input = this.querySelector('[type="text"]');
    this.clear = this.querySelector('[type="button"]');
    this.content = this.querySelector('[data-content]');
    this.defaultContent = this.content.innerHTML;
    this.request = new XMLHttpRequest();

    this.input.addEventListener('input', this.onInputHandler.bind(this));
    this.clear.addEventListener('click', () => {
      this.input.value = '';
      this.clear.classList.add('d-none');
      this.input.focus();
    });
  }

  onInputHandler() {
    clearTimeout(this.timeout);
    const { value } = this.input;
    const url = new URL(smily.website.publicSearch, window.location.origin);
    url.searchParams.set('locale', smily.website.lang);

    this.clear.classList.toggle('d-none', !value);

    if (value === '') {
      this.content.innerHTML = this.defaultContent;
      return;
    }

    this.content.innerHTML = `<div class="text-center">${smily.templates.spinner}</div>`;

    // Options to disable searchable types

    if (!this.hasAttribute('data-disable-category')) {
      url.searchParams.append('searchable_types[]', 'Category');
    }

    if (!this.hasAttribute('data-disable-destination')) {
      url.searchParams.append('searchable_types[]', 'Destination');
    }

    if (!this.hasAttribute('data-disable-rental')) {
      url.searchParams.append('searchable_types[]', 'Rental');
    }

    if (!this.hasAttribute('data-disable-page')) {
      url.searchParams.append('searchable_types[]', 'Page');
    }

    // Delay request
    this.timeout = setTimeout(() => {
      // Abort previous request
      if (this.request) {
        this.request.abort();
      }

      url.searchParams.set('search_phrase', value);

      this.request.addEventListener('load', this.displayResults.bind(this));
      this.request.open('GET', url.toString(), true);
      this.request.send();
    }, 500);
  }

  displayResults(event) {
    const { data } = JSON.parse(event.target.responseText);
    const suggestions = {};

    // Clear content
    this.content.innerHTML = '';

    // Group results
    data.forEach((result) => {
      const type = result.attributes['searchable-type'];
      if (!suggestions[type]) {
        suggestions[type] = [];
      }
      suggestions[type].push(result.attributes);
    });

    // Create suggestions list
    const suggestionsObj = Object.entries(suggestions);
    suggestionsObj.forEach(([key, value]) => {
      const groupName = key;
      const results = value;

      // Sort alphabetically by title
      this.sort(value);

      // Display group name
      this.displayHeading(groupName);

      // Display list
      results.forEach((anchor) => this.displayLink(anchor));
    });

    if (!suggestionsObj.length) {
      this.content.insertAdjacentHTML(
        'afterbegin',
        `<div class="py-1 text-muted text-center">${smily.website.t.noResult}</div>`,
      );
    }
  }

  displayHeading(name) {
    const head = document.createElement('h6');
    head.setAttribute('class', 'mt-2 mb-0');
    head.textContent = smily.website.t.websiteSearch.types[name.toLowerCase()] || name;
    this.content.appendChild(head);
  }

  displayLink(anchor) {
    const { title, url } = anchor;
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.textContent = title;
    this.content.appendChild(link);
  }

  sort(array) {
    array.sort((a, b) => {
      const titleA = a.title.toUpperCase();
      const titleB = b.title.toUpperCase();
      const after = titleA > titleB ? 1 : 0;
      return titleA < titleB ? -1 : after;
    });
  }
}

customElements.define('website-form', WebsiteForm);
