/* global smily */

// Content Expandable
class ContentExpandable extends HTMLElement {
  constructor() {
    super();

    this.showMore = smily.website.t.showMore ?? 'Show more';
    this.showLess = smily.website.t.showLess ?? 'Show less';
    this.ariaExpanded = false;
    this.collapsedHeight = '300px';
  }

  get ariaExpanded() {
    return this.getAttribute('aria-expanded') === 'true';
  }

  set ariaExpanded(value) {
    this.setAttribute('aria-expanded', value);
  }

  connectedCallback() {
    this.style.display = 'block';
    this.content = document.createElement('div');
    this.content.innerHTML = this.innerHTML;
    this.innerHTML = '';
    this.appendChild(this.content);

    this.toggler = document.createElement('a');
    this.toggler.textContent = this.showMore;
    this.toggler.href = '#';
    this.toggler.addEventListener('click', this.toggle.bind(this));

    this.appendChild(this.toggler);

    if (parseInt(getComputedStyle(this.content).height) > parseInt(this.collapsedHeight)) {
      this.content.classList.add('transition-base');
      this.content.classList.add('mask-image');
      this.content.style.display = 'block';
      this.content.style.overflow = 'hidden';
      this.content.style.height = this.collapsedHeight;
      this.toggler.style.display = 'inline';
    } else {
      this.toggler.style.display = 'none';
    }
  }

  toggle(event) {
    event.preventDefault();

    this.ariaExpanded = !this.ariaExpanded;
    this.toggler.textContent = this.ariaExpanded ? this.showLess : this.showMore;
    this.content.style.height = this.ariaExpanded ? `${this.content.scrollHeight}px` : this.collapsedHeight;
    this.content.classList.toggle('mask-image', !this.ariaExpanded);

    if (!this.ariaExpanded
      && window.scrollY > smily.offsetTop(this) + parseInt(this.collapsedHeight)
    ) {
      window.scroll({ top: smily.offsetTop(this), behavior: 'smooth' });
    }
  }
}

customElements.define('content-expandable', ContentExpandable);
