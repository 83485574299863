/* global smily */

// Show element on scroll

class ToggleOnScroll extends HTMLElement {
  constructor() {
    super();

    if (smily.mediaBreakPoint.up(this.dataset.breakpointUp || 'xs')) {
      const callback = (entries) => {
        entries.forEach((entry) => {
          this.classList.toggle('show', !entry.isIntersecting);
        });
      };

      const options = {
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      };

      const observer = new IntersectionObserver(callback, options);

      observer.observe(this.target);
    }
  }

  get target() {
    return document.querySelector(this.getAttribute('target'));
  }

  get rootMargin() {
    return this.dataset.rootMargin || '0px';
  }

  get threshold() {
    return this.dataset.threshold || '0.0';
  }
}

customElements.define('toggle-on-scroll', ToggleOnScroll);
