/* eslint-disable object-curly-newline */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* global smily, bootstrap, MediaBreakPoint, fbq, gtag, ga, BSA, cookiesWidget */

if (typeof window.smily === 'undefined') {
  window.smily = {};
}

if (typeof window.smily.website === 'undefined') {
  window.smily.website = {};
}

if (typeof window.smily.website.apiHost === 'undefined') {
  const isStagingWebsite = new URL(document.location).origin === 'https://test-genota.staging.happystay.com';
  window.smily.website.apiHost = isStagingWebsite ? 'https://secure-staging.smily.com' : 'https://secure.smily.com';
}

// Smily Analytics

window.smily.analytics = {
  domain: `https://${window.location.hostname === 'test-genota.staging.happystay.com' ? 'analytics-staging' : 'analytics'}.apps.bookingsync.com`,
  params: document.location.search,
};

smily.analytics.tracker = new BSA.Tracker({ domain: smily.analytics.domain });

try {
  const { controller, action } = smily.website.currentPage;
  const { tracker, params } = smily.analytics;

  if (controller === 'home') {
    tracker.track('home_page_visited', { params });
  } else if (controller === 'search' && action === 'show') {
    tracker.track('search_page_visited', { params });
  } else if (controller === 'destinations' && action === 'show') {
    tracker.track('destination_page_visited', { params });
  } else if (controller === 'categories' && action === 'show') {
    tracker.track('category_page_visited', { params });
  } else if (controller === 'rentals' && action === 'show') {
    const rentalsSearchId = new URLSearchParams(params).get('rentals_search_id');
    tracker.trackRentalPageVisited(
      tracker.rentalPageVisitId(),
      smily.rental.bookingsyncId,
      { rentals_search_id: rentalsSearchId },
    );
  } else if (controller === '/contact_requests') {
    tracker.track('contact_page_visited', { params });
  }
} catch (error) {
  console.error(error);
}

// Track cookies consent

(() => {
  try {
    const track = () => {
      const { categories } = cookiesWidget.preferences;

      if (!categories) return;

      const analytics = categories.includes('analytics');
      smily.analytics.tracker.track(
        analytics ? 'analytics_cookies_accepted' : 'analytics_cookies_rejected',
        { submited_at: new Date().getTime() },
      );
    };

    cookiesWidget.on('accepted', () => { track(); });
    cookiesWidget.on('rejected', () => { track(); });
  } catch (error) {
    console.log(error);
  }
})();

// Helpers

smily.formatPrice = (price, currency = smily.website.currency.iso) => new Intl.NumberFormat(
  smily.website.lang,
  { style: 'currency', currency, minimumFractionDigits: 0 },
).format(Math.ceil(Number(price)));

smily.t = (field, obj = {}) => {
  let t = field;

  Object.keys(obj).forEach((key) => {
    t = t.replace(`%{${key}}`, obj[key]);
  });

  return t;
};

smily.formatDates = (start, end) => {
  const isMonthBeforeDay = () => {
    // Get the positions of the month and the day in the date string
    const testDate = new Date(2000, 11, 31); // December 31, 2000
    const dateString = testDate.toLocaleDateString(smily.website.lang);
    const monthPosition = dateString.indexOf('12');
    const dayPosition = dateString.indexOf('31');

    return monthPosition < dayPosition;
  };

  if (smily.monthBeforeDay === undefined) smily.monthBeforeDay = isMonthBeforeDay();
  const { monthBeforeDay } = smily;

  const month = 'short';
  const day = 'numeric';
  const year = 'numeric';

  const dateFormatter = (date, customOptions) => {
    const options = { year, month, day };
    return date.toLocaleDateString(smily.website.lang, customOptions || options);
  };

  // start and end date are in YYYY-MM-DD format
  const startDate = new Date(start);
  const endDate = new Date(end);

  // if year is the same, show year only in the end date, otherwise show it in both
  const sameYear = startDate.getFullYear() === endDate.getFullYear();
  const sameMonth = startDate.getMonth() === endDate.getMonth();

  let formattedDates;

  if (sameYear && sameMonth) {
    // example : Mar 12 – 21, 2024
    const formattedStart = dateFormatter(startDate, monthBeforeDay ? { month, day } : { day });
    const formattedEnd = dateFormatter(endDate, monthBeforeDay ? { day } : { month, day });
    formattedDates = `${formattedStart} - ${formattedEnd}, ${endDate.getFullYear()}`;
  } else if (sameYear) {
    // example : Mar 13 – Apr 15, 2024
    // eslint-disable-next-line max-len
    const formattedStart = dateFormatter(startDate, monthBeforeDay ? { month, day } : { day, month });
    const formattedEnd = dateFormatter(endDate, monthBeforeDay ? { month, day } : { day, month });
    formattedDates = `${formattedStart} - ${formattedEnd}, ${endDate.getFullYear()}`;
  } else {
    // example : Mar 13, 2024 – Apr 15, 2025
    const formattedStart = dateFormatter(startDate);
    const formattedEnd = dateFormatter(endDate);
    formattedDates = `${formattedStart} - ${formattedEnd}`;
  }

  return formattedDates;
};
// Templates

smily.templates = {};

smily.svgIcon = (id, options = {}) => {
  const o = {
    w: 12, h: 12, class: '', vb: '0 0 24 24', ...options,
  };
  return `<svg xmlns="http://www.w3.org/2000/svg" height="${o.h}" width="${o.w}" class="${o.class}" viewBox="${o.vb}"><use href="#${id}" /></svg>`;
};

smily.templates.spinner = '<div class="mx-auto my-5 spinner-grow text-primary" role="status"></div>';

smily.templates.dropdown = (strings = {}) => {
  const s = {
    btnClass: '',
    label: '',
    content: '',
    clear: '',
    next: '',
    submit: '',
    validate: '',
    ...strings,
  };

  return `
    <button data-bs-toggle="dropdown" class="dropdown-toggle ${s.btnClass}">${s.label}</button>
    <div class="dropdown-menu">
      <header class="dropdown-header">
        <button type="button" name="back" class="btn invisible">${smily.svgIcon('leftArrowAlt', { w: 16, h: 16 })}</button>
        <span>${s.label}</span>
        <button type="button" name="next" class="btn invisible">${smily.svgIcon('rightArrowAlt', { w: 16, h: 16 })}</button>
      </header>
      <div class="dropdown-content">${s.content}</div>
      <hr class="dropdown-divider" size="">
      <footer class="dropdown-footer">
        <button type="reset" class="btn btn-link">${s.clear}</button>
        <button type="submit" class="btn btn-primary">${s.submit}</button>
        <button type="button" name="validate" class="btn btn-primary">${s.validate}</button>
      </footer>
    </div>
  `;
};

smily.templates.destinationDropdown = `
  <div class="position-sticky bg-white px-3 py-2 top-0 zindex-1">
    <div class="position-relative">
      <input type="search" class="form-control" tabindex="1" placeholder="${smily.website.t.rentalsForm.destinations.placeholder}" value="" autocomplete="off">
      <button type="button" class="btn-close d-none" aria-label="Clear"></button>
    </div>
  </div>
  <div data-no-result class="py-1 text-muted text-center d-none">${smily.website.t.noResult}</div>
`;

smily.templates.rentalCard = (rental = {}) => {
  const photos = rental.photos.slice(0, 10);
  const rentalUrl = new URL(rental.url);
  let { headline } = rental;

  if (typeof headline === 'string') {
    // remove host tag from headline
    headline = headline.replace(/guestready - /i, '').replace(/demo - /i, '');
  }

  if (smily.isDebug) rentalUrl.searchParams.set('debug', smily.isDebug);
  if (smily.variant) rentalUrl.searchParams.set('variant', smily.variant);

  /**
   * @param {string} url
   * @return {string} Ex: https://cdn.cloudinary.com/bookingsync/image/upload/[cloudinary_settings]/version/id.jpg
   */
  const cloudinaryUrl = (url) => {
    const newUrl = url.split('/');
    newUrl.splice(6, 1, 'f_auto,g_center,w_576,ar_3:2,q_80,c_fill');

    if (smily.website.imgWatermark) {
      newUrl.splice(7, 0, smily.website.imgWatermark);
    }

    return newUrl.join('/');
  };

  const swiperItems = photos.map((photo) => `
    <swiper-slide class="overflow-hidden" lazy="true">
      <img class="img-ratio-3x2 d-block w-100 h-100 img-object-fit-cover" loading="lazy"
        src="${cloudinaryUrl(photo.url)}" alt="${headline}">
      <a class="stretched-link" href="${rentalUrl}" title="${headline}" ${smily.mediaBreakPoint.up('lg') ? 'target="_blank"' : ''}></a>
    </swiper-slide>
  `).join('');

  const dateFormat = new Intl.DateTimeFormat(smily.website.lang, { dateStyle: 'medium' });
  const specialOffers = rental.special_offers.slice(0, 1);
  const specialOffer = specialOffers.map((offer) => `
    <span class="badge bg-primary rounded-pill text-wrap mb-1">
      <i class="icon-tags"></i>
      <span class="fw-bold">-${Math.round(offer.discount)}%</span>
      <span class="fw-normal">
        ${dateFormat.format(new Date(offer.start_date))} → ${dateFormat.format(new Date(offer.end_date))}
      </span>
    </span>
    <br>
  `).join('');

  const reviews = () => {
    const { reviews_average_rating, reviews_count } = rental;

    if (!reviews_count) return '';

    let rating;

    if (reviews_average_rating < 1.6) {
      rating = 0;
    } else if (reviews_average_rating < 3.2) {
      rating = 50;
    } else {
      rating = 100;
    }

    return `
      <span class="m-0 d-inline-flex icon-group gap-1 badge bg-white rounded-pill">
        ${smily.svgIcon(`star${rating}`)}
        <div class="visually-hidden"><span itemprop="ratingValue">${reviews_average_rating}</span>/5</div>
        <span class="text-black">
          ${reviews_average_rating} <small class="fw-normal">(<span itemprop="reviewCount">${reviews_count}</span>)</small>
        </span>
      </span>
    `;
  };

  const price = () => {
    const { currency } = rental;

    if (rental.final_price && rental.final_price > 0) {
      const { length_of_stay } = rental;
      const t = smily.website.t.rentalsCards.price_kind.n_nights[length_of_stay < 2 ? 'one' : 'other'];
      return `
        <span itemprop="priceRange">
          <span class="calculated fw-bold">${smily.formatPrice(rental.final_price, currency)}</span>
          <span class="kind small">
            ${t.replace('%{count}', length_of_stay)}
          </span>
        </span>
      `;
    }

    if (rental.min_price === rental.max_price) {
      return `
        <span itemprop="priceRange">
          <span class="uniq fw-bold">${smily.formatPrice(rental.max_price, currency)}</span>
          <span class="kind small">${rental.price_kind}</span>
        </span>
      `;
    }

    return `
      <span itemprop="priceRange">
        <span class="min fw-bold">${smily.formatPrice(rental.min_price, currency)}</span> →
        <span class="max fw-bold">${smily.formatPrice(rental.max_price, currency)}</span>
        <span class="kind small">${rental.price_kind}</span>
      </span>
    `;
  };

  const destinations = () => {
    const rentalDestinations = rental.destination;
    const lastTwo = rentalDestinations?.splice(-2);

    return lastTwo?.reverse()?.map((destination) => destination.title)?.join(', ');
  };

  const details = () => {
    let html = '';

    if (rental.sleeps) {
      const sleeps = rental.sleeps_max > 0 && rental.sleeps_max > rental.sleeps ? `${rental.sleeps}-${rental.sleeps_max}` : rental.sleeps;
      html += `
        <span class="icon-group fill-current-color">
          ${smily.svgIcon('guests', { class: 'text-muted fs-6' })}
          <span>${sleeps}</span>
        </span>
      `;
    }

    if (rental.bedrooms_count) {
      html += `
        <span class="icon-group fill-current-color">
          ${smily.svgIcon('bed', { class: 'text-muted fs-6' })}
          <span>${rental.bedrooms_count}</span>
        </span>
      `;
    }

    if (rental.full_bathrooms_count) {
      html += `
        <span class="icon-group fill-current-color">
          ${smily.svgIcon('bath', { class: 'text-muted fs-6' })}
          <span>${rental.full_bathrooms_count}</span>
        </span>
      `;
    }

    if (rental.surface_with_unit) {
      html += `
        <span class="icon-group fill-current-color">
          <span>${rental.surface_with_unit}</span>
        </span>
      `;
    }

    return html;
  };

  return `
    <article id="rental-${rental.id}" data-href="${rentalUrl}" data-lat="${rental.lat}" data-lng="${rental.lng}" data-price="${rental.final_price || rental.min_price}"
      class="card flex-grow-1 rental-card shadow-sm zoom" ${rental.scope === 'rentalsForm' ? 'data-rentals-map-marker' : ''}>
      <a class="stretched-link" href="${rentalUrl}" title="${headline}" ${smily.mediaBreakPoint.up('lg') ? 'target="_blank"' : ''}></a>

      <div class="position-relative">
        <swiper-container
          class="bg-dark bg-opacity-25"
          navigation-prev-el="[data-swiper-target-prev='${rental.scope}-${rental.id}']"
          navigation-next-el="[data-swiper-target-next='${rental.scope}-${rental.id}']"
          pagination="true"
          pagination-dynamic-bullets="true"
          loop="true">
          ${swiperItems}
        </swiper-container>

        <span class="swiper-button-prev-custom fill-current-color ms-2" data-swiper-target-prev="${rental.scope}-${rental.id}" aria-hidden="true">
          ${smily.svgIcon('leftArrow', { w: 16, h: 16, class: 'm-2', vb: '0 0 512 512' })}
        </span>
        <span class="swiper-button-next-custom fill-current-color me-2" data-swiper-target-next="${rental.scope}-${rental.id}" aria-hidden="true">
          ${smily.svgIcon('rightArrow', { w: 16, h: 16, class: 'm-2', vb: '0 0 512 512' })}
        </span>

        <div class="d-flex flex-column pe-none card-img-overlay p-2 zindex-1">
          <div>${reviews()}</div>
          <div>${specialOffer}</div>
        </div>
      </div>

      <div class="card-body">
        <h3 class="card-title h5 mb-1 text-black text-truncate">${headline}</h3>
        <small class="text-muted">${destinations()}</small>
        <span class="d-block text-primary">${price()}</span>
      </div>
      <ul class="list-group list-group-flush flex-grow-0 border-top text-muted mx-3 py-2">
        <li class="list-group-item d-flex justify-content-around p-0 py-1 small">
          ${details()}
        </li>
      </ul>

      <rentals-wishlist-add rental-id="${rental.id}" data-rental-bs-id="${rental.bookingsync_id}" data-rental-name="${rental.name}" class="zindex-1 shadow-sm rounded-circle position-absolute end-0 top-0 m-2" role="button" aria-label="Add rental to wishlist" tabindex="0">
        ${smily.svgIcon('heart', { w: 16, h: 16, class: 'm-2 fill-current-color' })}
      </rentals-wishlist-add>
    </article>
  `;
};

smily.templates.rentalCardPlacehoder = `
  <div class="card-placeholder">
    <div class="card shadow-sm" aria-hidden="true">
      <svg class="card-img-top img-ratio-3x2 d-block w-100" width="100%" height="180" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect></svg>
      <div class="card-body">
        <p class="card-text placeholder-glow mb-1">
          <span class="placeholder col-2 mt-1 mb-2"></span>
          <span class="placeholder col-8 d-block"></span>
        </p>
        <h5 class="card-title placeholder-glow">
          <span class="placeholder col-11"></span>
        </h5>
        <p class="card-text placeholder-glow mb-4">
          <span class="placeholder col-8"></span>
          <span class="placeholder col-12"></span>
          <span></span>
        </p>
      </div>
    </div>
  </div>
`;

smily.templates.noRentalCard = `
  <div class="w-100 mt-4">
    <h3>${smily.website.t.rentalsForm.no_rentals.title}</h3>
    <a href="${smily.website.searchPath}">${smily.website.t.rentalsForm.no_rentals.reset}</a>
  </div>
`;

smily.templates.modal = (strings = {}) => {
  const s = {
    modalClass: 'fade',
    id: '',
    dialogClass: '',
    title: '',
    content: '',
    bodyClass: '',
    itemsWrapperClass: '',
    ...strings,
  };

  return `
    <div class="modal ${s.modalClass}" id="${s.id}" tabindex="-1" aria-labelledby="${s.id}Label" aria-hidden="true">
      <div class="modal-dialog ${s.dialogClass}">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title order-1 order-lg-0 me-auto ms-3 ms-lg-0" id="${s.id}Label">${s.title}</h5>
            <button type="button" class="btn-close ms-0" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body ${s.bodyClass}">
            <div class="${s.contentClass}" data-content>${s.content}</div>
          </div>
        </div>
      </div>
    </div>
  `;
};

// Bootstrap Modal with window.history

smily.modal = (element, options = null) => {
  const isModalIdPresent = () => element.hasAttribute('id');
  const currentHash = () => window.document.location.hash;
  const modalHash = `#state_${element.id}`;

  const showModalBasedOnHash = () => {
    if (currentHash() === modalHash) {
      bootstrap.Modal.getInstance(element)?.show();
    }
  };

  const setUpModalHistory = () => {
    window.document.addEventListener('DOMContentLoaded', showModalBasedOnHash);

    element.addEventListener('show.bs.modal', () => {
      if (currentHash() !== modalHash) {
        window.history.pushState(modalHash, null, modalHash);
      }
    });

    const handlePopState = () => {
      const modalInstance = bootstrap.Modal.getInstance(element);
      if (currentHash() === modalHash) {
        modalInstance?.show();
      } else {
        modalInstance?.hide();
      }
    };

    window.addEventListener('popstate', handlePopState);

    element.addEventListener('hidden.bs.modal', () => {
      if (currentHash() === modalHash) {
        window.removeEventListener('popstate', handlePopState);
        window.history.back();
        window.addEventListener('popstate', handlePopState);
      }
    });
  };

  if (isModalIdPresent() && window.history?.pushState) {
    setUpModalHistory();
  }

  return new bootstrap.Modal(element, options);
};

Array.from(document.querySelectorAll('.modal')).forEach((modal) => {
  if (modal.hasAttribute('id')) {
    smily.modal(modal);
  }
});

// Utilities

smily.mediaBreakPoint = new MediaBreakPoint();

smily.debounce = (fn, delay = 100) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  };
};

smily.isInViewport = (element) => {
  if (!(element instanceof HTMLElement)) return false;

  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

smily.offsetTop = (element) => {
  const el = element;
  let offsetTop = 0;

  if (!(el instanceof HTMLElement)) return 0;

  offsetTop = el.offsetTop;

  document.querySelectorAll('.fixed-top, .sticky-top').forEach((e) => {
    if (smily.isInViewport(e)) {
      offsetTop -= parseInt(getComputedStyle(e).height);
    }
  });

  return offsetTop;
};

/**
 * Calculates the number of nights between two dates.
 * @param {string} startDateStr - The start date in the format "YYYY-MM-DD".
 * @param {string} endDateStr - The end date in the format "YYYY-MM-DD".
 * @returns {number} The number of nights between the two dates.
 */
smily.getNumNights = (startDateStr, endDateStr) => {
  const ONE_DAY_MS = 1000 * 60 * 60 * 24;
  const startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const diffMs = endDate.getTime() - startDate.getTime();
  const diffDays = Math.floor(diffMs / ONE_DAY_MS);
  return diffDays > 0 ? diffDays : 0;
};

smily.isDebug = new URL(document.location).searchParams.get('debug');
smily.variant = new URL(document.location).searchParams.get('variant');

smily.trackers = {
  ga: (...data) => {
    try {
      if (smily.isDebug) {
        console.log('%cSmily Tracker - gtag', 'color:DodgerBlue', data);
      } else {
        gtag(...data);
      }
    } catch (e) {
      // silence
    }

    try {
      if (smily.isDebug) {
        console.log('%cSmily Tracker - ga', 'color:DodgerBlue', data);
      } else {
        ga(...data);
      }
    } catch (e) {
      // silence
    }
  },
  fbq: (...data) => {
    try {
      if (typeof fbq !== 'function') return;
      if (smily.isDebug) {
        console.log('%cSmily Tracker - fbq', 'color:DodgerBlue', data);
      } else {
        fbq(...data);
      }
    } catch (e) {
      // silence
    }
  },
};

smily.paramsToRailsFormat = (params) => {
  const railsFormatParams = [];

  Object.entries(params).forEach(([key, value]) => {
    if (key.includes('[]')) {
      const v = typeof value === 'string' ? value.split(',') : value;
      v?.forEach((val) => railsFormatParams.push([key, val]));
    } else {
      railsFormatParams.push([key, value]);
    }
  });

  return railsFormatParams;
};

// rentalsList from search api
smily.rentalsList = async (filters = {}, endpointPath = smily.website.rentalsListPath) => {
  if (typeof filters !== 'object') {
    throw new Error('filters must be an Object');
  }

  const railsFormatFilters = smily.paramsToRailsFormat(filters);
  const url = new URL(endpointPath, window.location.origin);
  url.search = new URLSearchParams(railsFormatFilters);

  url.searchParams.set('locale', smily.website.lang);

  const endpoint = url.toString();
  return fetch(endpoint, { cache: 'force-cache' }).then((res) => {
    const date = res.headers.get('date');
    const dt = date ? new Date(date).getTime() : 0;
    // If it's older than 5 minutes
    if (dt < Date.now() - 300_000) {
      return fetch(endpoint, { cache: 'default' });
    }
    return res;
  }).then((response) => response.json());
};

smily.RentalsCardsList = (rentals, scope, template = '<div class="col d-flex">#{content}</div>') => {
  if (!rentals.length) return smily.templates.noRentalCard;

  const rentalsList = rentals;
  const rentalsForm = document.querySelector('rentals-form');
  const trackedParams = rentalsForm.getTrackedParams();
  let rentalsSearchId;

  try {
    rentalsSearchId = smily.analytics.tracker.rentalsSearchId(trackedParams);
  } catch (error) {
    // silence
  }

  return rentalsList.map((rental) => {
    if (!rental.headline || !rental.url || !rental.photos.length) return '';

    const r = rental;
    r.scope = scope;

    if (scope === 'rentalsForm') {
      r.url = new URL(r.url);

      if (rentalsSearchId) {
        r.url.searchParams.set('rentals_search_id', rentalsSearchId);
      }
    }

    return template.replace('#{content}', smily.templates.rentalCard(r));
  }).join('');
};

smily.RentalsCardsPlacholders = (num = 16, template = '#{content}') => new Array(num).fill(template.replace('#{content}', smily.templates.rentalCardPlacehoder)).join('');

// Intersection Animation

smily.intersectionAnimation = () => {
  const toggleClass = (entry) => {
    const { target } = entry;
    target.parentElement.style.overflow = entry.isIntersecting ? 'hidden' : null;
    // TODO: maybe add an option to unobserve
    // io.unobserve(target);
    target.addEventListener('transitionend', () => {
      target.parentElement.style.overflow = entry.isIntersecting ? null : 'hidden';
    }, { once: true });

    target.classList.toggle('intersection-animated', entry.isIntersecting);
  };

  const elements = Array.from(document.querySelectorAll('.intersection-animation'));
  const io = new IntersectionObserver((entries) => entries.forEach(toggleClass));

  elements.forEach((element) => {
    io.observe(element);
  });
};

document.addEventListener('DOMContentLoaded', smily.intersectionAnimation);

// Parallax

(() => {
  if (smily.mediaBreakPoint.down('lg')) {
    return;
  }
  const parallaxElements = document.querySelectorAll('.parallax');
  parallaxElements.forEach((element) => {
    const el = element;
    el.style.willChange = 'transform';
    window.addEventListener('scroll', () => {
      const { scrollY, pageYOffset } = window;
      const { offsetTop } = el.parentElement;
      const height = parseInt(getComputedStyle(el).height);
      const vh50 = window.innerHeight * 0.5;

      if (scrollY >= offsetTop - vh50 && scrollY <= offsetTop + height) {
        const y = parseInt((pageYOffset * 0.4) - offsetTop);
        const transform = `translate3d(0px, ${y < 0 ? 0 : y}px, 0px)`;
        el.style.transform = transform;
        el.style.mozTransform = transform;
        el.style.webkitTransform = transform;
      }
    });
  });
})();

// Open external links in new tab and set current link active
(() => {
  const currentUrl = new URL(document.location);
  currentUrl.search = '';

  const isExternalHref = (element) => element.href?.includes(window.location.host);

  if (smily.website.externalLinks?.disabled !== true) {
    document.querySelectorAll('a').forEach((element) => {
      if (!isExternalHref(element)) {
        element.setAttribute('target', '_blank');
      }
    });
  }

  document.querySelectorAll('nav a').forEach((element) => {
    if (isExternalHref(element) && element.getAttribute('href') !== '#') {
      const url = new URL(element.href);
      url.search = '';
      element.classList.toggle('active', url.toString() === currentUrl.toString());
    }
  });
})();

// Add params to anchors
(() => {
  const params = [
    'checkin',
    'checkout',
    'guests',
    'adults',
    'children',
    'debug',
  ];

  const documentSearchParams = Array.from(new URLSearchParams(document.location.search));
  const filteredSearchParams = documentSearchParams.filter(([key]) => params.includes(key));
  const trackedParams = Object.fromEntries(filteredSearchParams);

  Array.from(document.querySelectorAll('a[data-params-to-anchors]')).forEach((anchor) => {
    let { href } = anchor;
    const url = new URL(href);
    const source = new URLSearchParams(url.search);
    const target = new URLSearchParams(trackedParams);
    const combined = {
      ...Object.fromEntries(source),
      ...Object.fromEntries(target),
    };

    url.search = new URLSearchParams(combined);
    href = url.toString();

    anchor.setAttribute('href', href);
  });
})();

// Object fit position
smily.objectPosition = (image) => {
  const positionY = 100 - parseInt(image.dataset.centerHeight);
  // eslint-disable-next-line no-param-reassign
  image.style.objectPosition = `50% ${positionY}%`;
};

document.querySelectorAll('img[data-center-height]').forEach(smily.objectPosition);

// Hover to open Dropdowns

document.querySelectorAll('.dropdown-hover').forEach((dropdown) => {
  dropdown.parentElement.addEventListener('mouseenter', (event) => {
    event.currentTarget.querySelector('.dropdown-menu').classList.add('show');
  });

  dropdown.parentElement.addEventListener('mouseleave', (event) => {
    event.currentTarget.querySelector('.dropdown-menu').classList.remove('show');
  });
});

// Detect iOSSafari

const { userAgent } = window.navigator;
const iOSSafari = (/(iPad|iPhone|iPod)/gi).test(userAgent)
  && !(/CriOS/).test(userAgent) && !(/FxiOS/).test(userAgent)
  && !(/OPiOS/).test(userAgent) && !(/mercury/).test(userAgent);

// The trick to viewport units on mobile

function viewportHandler() {
  const viewport = window.visualViewport;
  const vh = viewport.height * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

if (iOSSafari) {
  viewportHandler();
  window.visualViewport.addEventListener('resize', viewportHandler);
}

// Set css variable for Navbar height

(() => {
  const navbar = document.querySelector('.navbar');
  const submenu = document.querySelector('.navbar-submenu');

  if (navbar) {
    let height = navbar.offsetHeight;

    if (submenu) {
      height += submenu.offsetHeight;
      document.documentElement.style.setProperty('--navbar-height', `${height}px`);
      return;
    }

    document.documentElement.style.setProperty('--navbar-height', `${height}px`);
  }
})();

// Init tooltips

(() => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl));
})();
