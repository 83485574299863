/* global smily */

class RentalsViewed extends HTMLElement {
  constructor() {
    super();

    this.rentalsIds = JSON.parse(window.localStorage.getItem('rentalsViewedIds')) ?? [];
    this.wrapper = this.querySelector('[data-content]');

    const { rentalId } = smily.website.currentPage;
    const rentalsIds = this.rentalsIds.filter((id) => id !== rentalId && id !== null);

    if (rentalsIds.length) {
      this.classList.remove('d-none');
      this.fetchRentals(rentalsIds.slice(0, 4));
    }
  }

  async fetchRentals(rentalsIds) {
    // add cards placeholders
    this.wrapper.insertAdjacentHTML('beforeend', smily.RentalsCardsPlacholders(4));

    const data = await smily.rentalsList({ 'ids[]': rentalsIds });

    // remove cards placeholders
    this.wrapper.querySelectorAll('.card-placeholder').forEach((card) => card.remove());

    if (data.rentals.length) {
      this.wrapper.insertAdjacentHTML('beforeend', smily.RentalsCardsList(data.rentals, 'rentalsViewed'));
    } else {
      this.classList.add('d-none');
    }
  }
}

customElements.define('rentals-viewed', RentalsViewed);

// Store rentals viewed ids

document.addEventListener('DOMContentLoaded', () => {
  let rentalsIds = JSON.parse(window.localStorage.getItem('rentalsViewedIds')) ?? [];
  const { rentalId } = smily.website.currentPage;

  if (rentalId) {
    if (rentalsIds.includes(rentalId)) {
      const index = rentalsIds.indexOf(rentalId);
      delete rentalsIds[index];
    }

    rentalsIds.push(rentalId);
    rentalsIds = rentalsIds.filter((id) => id !== null);
    window.localStorage.setItem('rentalsViewedIds', JSON.stringify(rentalsIds));
  }
});
