class MediaBreakPoint { // eslint-disable-line no-unused-vars
  constructor() {
    this.breakPoints = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];

    const element = document.createElement('div');
    document.body.appendChild(element);
    element.classList.add('media-break-points');

    this.breakPoints.forEach((name) => {
      const child = document.createElement('div');

      if (name === 'xs') {
        child.classList.add('d-block');
        child.classList.add('d-sm-none');
      } else {
        child.classList.add('d-none');
        child.classList.add(`d-${name}-block`);
      }

      child.dataset.name = name;
      element.appendChild(child);
    });
  }

  isVisible(e) {
    return e.offsetWidth > 0 || e.offsetHeight > 0 || e.getClientRects().length > 0;
  }

  current() {
    const elements = document.querySelectorAll('.media-break-points > div');
    const element = Array.from(elements).filter(this.isVisible)[0];
    return element?.dataset?.name;
  }

  up(name) {
    return this.breakPoints.indexOf(this.current()) < this.breakPoints.indexOf(name);
  }

  down(name) {
    return this.breakPoints.indexOf(this.current()) > this.breakPoints.indexOf(name);
  }

  only(name) {
    return this.current() === name;
  }
}
