/* eslint-disable no-param-reassign */
/* global smily */
/**
 * @typedef {Object} RangeSliderOptions
 * @property {HTMLElement} element - The element to append the slider to
 * @property {number} min - The minimum value
 * @property {number} max - The maximum value
 * @property {number} minValue - The minimum value
 * @property {number} maxValue - The maximum value
 * @property {number} step - The step value
 * @property {number} chartSteps - The number of steps in the chart
 * @property {boolean} beyondMax - Allow values beyond the max value
 * @property {string} template - The template to use
 * @property {string} chartTemplate - The template to use for the chart
 * @property {string} chartIntervalTemplate - The template to use for the chart interval
 * @property {string} chartIntervalActiveClass - The class to add to the active chart interval
 * @property {string} chartIntervalClass - The class to add to the chart interval
 * @property {string} chartIntervalSelector - The selector to use for the chart interval
 * @property {string} chartSelector - The selector to use for the chart
 * @property {string} inputMaxSelector - The selector to use for the max input
 * @property {string} inputMinSelector - The selector to use for the min input
 * @property {string} leftSelector - The selector to use for the left element
 * @property {string} lineSelector - The selector to use for the line element
 * @property {string} rightSelector - The selector to use for the right element
 * @property {string} sliderSelector - The selector to use for the slider element
 * @property {string} wrapperSelector - The selector to use for the wrapper element
 * @property {string} onMove - The callback to call when the slider is moved
 * @property {string} onMoveEnd - The callback to call when the slider is moved
 * @property {string} onMoveStart - The callback to call when the slider is moved
 * @property {string} onValueChange - The callback to call when the slider is moved
 * @property {string} onValueChangeStart - The callback to call when the slider is moved
 * @property {string} onValueChangeEnd - The callback to call when the slider is moved
  */
class RangeSlider { // eslint-disable-line no-unused-vars
  constructor(options) {
    this.startX = 0;
    this.x = 0;

    this.wrapper = options.element;

    const template = `
      <div class="range-slider-chart"></div>
      <div class="range-slider" data-swipe-ignore>
        <div class="range-slider-left"><span></span></div>
        <div class="range-slider-right"><span></span></div>
        <div class="range-slider-line"><span></span></div>
      </div>
      <div class="hstack flex-nowrap gap-2 py-3">
        <div class="input-group flex-nowrap">
          <span class="input-group-text">${smily.website.currency.sym}</span>
          <input class="min-ch-10 form-control" type="text" name="min" autocomplete="off">
        </div>
        <div class="input-group flex-nowrap">
          <span class="input-group-text">${smily.website.currency.sym}</span>
          <input class="min-ch-10 form-control" type="text" name="max" autocomplete="off">
        </div>
      </div>
    `;

    this.wrapper.insertAdjacentHTML('beforeend', options.template || template);

    this.slider = this.wrapper.querySelector('.range-slider');
    this.chart = this.wrapper.querySelector('.range-slider-chart');

    this.min = parseInt(options.min) || 1;
    this.max = parseInt(options.max) || 10000;
    this.minValue = parseInt(options.minValue) || this.min;
    this.maxValue = parseInt(options.maxValue) || this.max;
    this.step = parseInt(options.step) || 0;
    this.chartSteps = parseInt(options.chartSteps) || 20;
    this.beyondMax = !!options.beyondMax;

    // Assign elements
    this.left = this.slider.querySelector('.range-slider-left');
    this.right = this.slider.querySelector('.range-slider-right');
    this.line = this.slider.querySelector('.range-slider-line span');

    this.inputMin = this.wrapper.querySelector('input[name="min"]');
    this.inputMax = this.wrapper.querySelector('input[name="max"]');

    // Fill inputs
    this.inputMin.value = this.minValue;
    this.inputMax.value = this.maxValue;

    if (this.beyondMax && this.maxValue === this.max) this.inputMax.value += '+';

    // Set range
    this.updateMinPos(this.minValue);
    this.updateMaxPos(this.maxValue);

    // Listeners
    this.onStartHandler = this.onStart.bind(this);
    this.onMoveHandler = this.onMove.bind(this);
    this.onStopHandler = this.onStop.bind(this);

    // Mouse events
    this.left.addEventListener('mousedown', this.onStartHandler);
    this.right.addEventListener('mousedown', this.onStartHandler);

    // Touch events
    this.left.addEventListener('touchstart', this.onStartHandler, { passive: true });
    this.right.addEventListener('touchstart', this.onStartHandler, { passive: true });

    // Input events
    this.inputMin.addEventListener('input', this.onInput.bind(this));
    this.inputMax.addEventListener('input', this.onInput.bind(this));

    // Init chart
    this.initChart();
    this.loadChartData();
  }

  initChart() {
    this.chart.innerHTML = '';
    this.chartIntervals = [];
    const length = Math.abs(this.min - this.max) / this.chartSteps;

    for (let i = 0; i < this.chartSteps; i += 1) {
      const min = this.min + (length * i);
      const max = min + length;
      const element = document.createElement('div');
      element.style.width = `${100 / this.chartSteps}%`;
      this.chart.appendChild(element);

      this.chartIntervals.push({
        element,
        min: parseInt(min.toFixed(1)),
        max: parseInt(max.toFixed(1)),
        rentals: 0,
      });
    }
  }

  updateMinPos(minValue) {
    const {
      line, left, min, max,
    } = this;

    const leftPercent = minValue < min ? min : (minValue / max) * 100;
    left.style.left = `${leftPercent}%`;
    line.style.marginLeft = `${leftPercent}%`;
  }

  updateMaxPos(maxValue) {
    const {
      line, right, max,
    } = this;

    const rightPercent = 100 - (maxValue > max ? max : (maxValue / max) * 100);
    right.style.right = `${rightPercent}%`;
    line.style.marginRight = `${rightPercent}%`;
  }

  updateChartInterval(interval) {
    interval.element.classList.toggle(
      'active',
      !(interval.max < this.minValue || interval.min > this.maxValue),
    );
  }

  updateChartIntervals() {
    this.chartIntervals.forEach((interval) => {
      this.updateChartInterval(interval);
    });
  }

  loadChartData() {
    const fetchData = async () => {
      const url = new URL(document.location);

      url.searchParams.set('currency', smily.website.currency.iso);
      url.searchParams.delete('min_price');
      url.searchParams.delete('max_price');

      const endpoint = `/${smily.website.lang}/rentals_prices${url.search}`;
      const res = await fetch(endpoint, { cache: 'force-cache' });
      const date = res.headers.get('date');
      const dt = date ? new Date(date).getTime() : 0;
      // If it's older than 5 minutes
      if (dt < Date.now() - 300000) {
        return fetch(endpoint, { cache: 'default' });
      }
      return res;
    };

    fetchData()
      .then((response) => response.json())
      .then((data) => {
        // Push rentals into intervals
        data.rentals_prices.forEach((price) => {
          const priceToInt = parseInt(price);

          if (priceToInt >= this.max) {
            this.chartIntervals[this.chartIntervals.length - 1].rentals += 1;
          } else {
            this.chartIntervals.find(
              // eslint-disable-next-line comma-dangle
              (interval) => (priceToInt >= interval.min) && (priceToInt <= interval.max)
            ).rentals += 1;
          }
        });

        // Get the highest interval value
        const intervals = this.chartIntervals.map((o) => o.rentals);
        const maxInIntervals = Math.max(...intervals);

        // Set intervals height
        this.chartIntervals.forEach((interval) => {
          const elementHeight = parseInt(((interval.rentals * 100) / maxInIntervals) || 0);
          if (elementHeight) {
            interval.element.style.height = `calc(${elementHeight}% + 12px)`;
          }
          this.updateChartInterval(interval);
        });
      })
      .catch(() => {
        this.chartIntervals.forEach((interval) => {
          interval.element.style.height = 0;
        });
      });
  }

  onStart(event) {
    // Prevent default dragging of selected content
    event.preventDefault();
    const target = event.currentTarget;
    let { pageX } = event;

    if (event.touches) {
      pageX = event.touches[0].pageX;
    }

    if (target === this.left) {
      this.x = this.left.offsetLeft;
    } else {
      this.x = this.right.offsetLeft;
    }

    this.startX = pageX - this.x;
    this.selected = target;

    // Mouse events
    document.body.addEventListener('mousemove', this.onMoveHandler);
    document.body.addEventListener('mouseup', this.onStopHandler);

    // Touch events
    document.body.addEventListener('touchmove', this.onMoveHandler);
    document.body.addEventListener('touchend', this.onStopHandler);

    if (this.onStartCb) {
      this.onStartCb(this.minValue, this.maxValue);
    }
  }

  onMove(event) {
    const {
      selected, line, left, right, slider,
    } = this;
    let { pageX } = event;

    if (event.touches) {
      pageX = event.touches[0].pageX;
    }

    this.x = pageX - this.startX;

    if (selected === left) {
      const offsetLeftMinusMargin = right.offsetLeft - left.offsetWidth + 10;
      if (this.x > offsetLeftMinusMargin) {
        // Prevent left handle to go over right handle
        this.x = offsetLeftMinusMargin;
      } else if (this.x < 0) {
        // Prevent left handle to go out of slider
        this.x = 0;
      }
      const leftPercent = (this.x / slider.offsetWidth) * 100;
      selected.style.left = `${leftPercent}%`;
      line.style.marginLeft = `${leftPercent}%`;
    } else if (selected === right) {
      const offsetLeftPlusMargin = left.offsetLeft + left.offsetWidth + 10;
      if (this.x < offsetLeftPlusMargin) {
        // Prevent right handle to go over left handle
        this.x = offsetLeftPlusMargin;
      } else if (this.x > slider.offsetWidth) {
        // Prevent right handle to go out of slider
        this.x = slider.offsetWidth;
      }
      const rightPercent = 100 - ((this.x / slider.offsetWidth) * 100);
      selected.style.right = `${rightPercent}%`;
      line.style.marginRight = `${rightPercent}%`;
    }

    // Write new value
    this.calculateValueFromPos();

    // Update chart
    this.updateChartIntervals();

    if (this.onMoveCb) {
      this.onMoveCb(this.minValue, this.maxValue);
    }
  }

  onStop() {
    // Mouse events
    document.body.removeEventListener('mousemove', this.onMoveHandler);
    document.body.removeEventListener('mouseup', this.onStopHandler);

    // Touch events
    document.body.removeEventListener('touchmove', this.onMoveHandler);
    document.body.removeEventListener('touchend', this.onStopHandler);

    this.selected = null;

    // Write new value
    this.calculateValueFromPos();

    if (this.onStopCb) {
      this.onStopCb(this.minValue, this.maxValue);
    }
  }

  onInput(event) {
    const { target } = event;

    // Restrict input to numbers
    target.value = target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    const value = parseInt(target.value);

    if (target === this.inputMin) {
      this.setMinValue(value);
    } else {
      this.setMaxValue(value);
    }
  }

  setMinValue(value) {
    const newValue = parseInt(value);

    if (newValue < this.min || newValue >= this.maxValue) return;

    this.minValue = newValue;
    this.updateMinPos(newValue);
    this.inputMin.value = newValue;

    this.updateChartIntervals();
    if (this.onStopCb) this.onStopCb(this.minValue, this.maxValue);
  }

  setMaxValue(value) {
    let newValue = parseInt(value);
    const isBeyondMax = newValue >= this.max;

    if (newValue <= this.minValue) return;
    if (isBeyondMax) newValue = parseInt(this.max);

    this.maxValue = newValue;
    this.updateMaxPos(newValue);

    if (isBeyondMax && this.beyondMax) newValue += '+';
    this.inputMax.value = newValue;

    this.updateChartIntervals();
    if (this.onStopCb) this.onStopCb(this.minValue, this.maxValue);
  }

  calculateValueFromPos() {
    const {
      line, min, max, step, slider,
    } = this;

    let minValue = line.offsetLeft / slider.offsetWidth;
    let maxValue = (line.offsetLeft + line.offsetWidth) / slider.offsetWidth;

    minValue = minValue * (max - min) + min;
    maxValue = maxValue * (max - min) + min;

    if (step !== 0) {
      let multi = Math.floor(minValue / step);
      minValue = step * multi;

      multi = Math.floor(maxValue / step);
      maxValue = step * multi;
    }

    this.inputMin.value = minValue;
    this.minValue = minValue;

    this.inputMax.value = maxValue;
    this.maxValue = maxValue;

    if (this.beyondMax && maxValue === this.max) this.inputMax.value += '+';
  }

  reset() {
    // Reset values
    this.inputMin.value = this.min;
    this.minValue = this.min;

    this.inputMax.value = this.max;
    this.maxValue = this.max;

    if (this.beyondMax) this.inputMax.value += '+';

    this.left.style.left = '0%';
    this.right.style.right = '0%';
    this.line.style.marginLeft = '0%';
    this.line.style.marginRight = '0%';
    this.startX = 0;
    this.x = 0;

    this.updateChartIntervals();

    if (this.didChanged) {
      this.didChanged(this.minValue, this.maxValue);
    }
  }
}
