/* global bootstrap */

/*
 * Auto Expand the menu based on its content width
 */

class NavBar extends HTMLElement {
  constructor() {
    super();

    this.container = this.querySelector('.container, .container-fluid');
    this.shrinkEvent = new CustomEvent('shrink');
    this.expandEvent = new CustomEvent('expand');

    window.addEventListener('resize', this.toggleExpand.bind(this));
    window.addEventListener('scroll', this.toggleClassesOnScroll.bind(this));

    const offcanvas = document.getElementById('navbarOffcanvas');

    if (offcanvas) {
      offcanvas.addEventListener('swiped-left', () => {
        bootstrap.Offcanvas.getInstance(offcanvas).hide();
      });
    }

    // Hide opened Offcanvas mobile cache (when back button is pressed)
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        if (!offcanvas) return;
        bootstrap.Offcanvas.getInstance(offcanvas).hide();
      }
    });
  }

  connectedCallback() {
    this.toggleExpand();
    window.addEventListener('DOMContentLoaded', this.toggleExpand.bind(this));
    window.addEventListener('DOMContentLoaded', this.toggleClassesOnScroll.bind(this));
  }

  toggleExpand() {
    const { container } = this;
    this.classList.add('navbar-expand');

    let childrenWidth = 0;

    container.querySelectorAll('.navbar-brand, .navbar-nav').forEach((child) => {
      const style = getComputedStyle(child);
      childrenWidth += child.getBoundingClientRect().width;
      childrenWidth += parseInt(style.marginLeft) + parseInt(style.marginRight);
    });

    const containerStyle = getComputedStyle(container);
    const { paddingLeft, paddingRight } = containerStyle;
    const containerPaddingX = parseInt(paddingLeft) + parseInt(paddingRight);
    const containerInnerWidth = container.clientWidth - containerPaddingX;
    const childrenOverflow = childrenWidth >= containerInnerWidth;

    if (childrenOverflow) {
      this.classList.remove('navbar-expand');
      this.dispatchEvent(this.shrinkEvent);
    } else {
      this.dispatchEvent(this.expandEvent);
    }

    document.body.classList.toggle('navbar-expanded', !childrenOverflow);
  }

  toggleClassesOnScroll() {
    const isTopPosition = window.scrollY > 0;
    const { onScrollClasses } = this.dataset;
    const classes = onScrollClasses.split(' ').filter((i) => i !== '');
    const brandImages = this.querySelectorAll('.navbar-brand > img');

    if (classes.length) {
      this.classList.toggle('navbar-light', !isTopPosition && classes.includes('navbar-dark'));
      this.classList.toggle('navbar-dark', !isTopPosition && classes.includes('navbar-light'));
      classes.map((cl) => this.classList.toggle(cl, isTopPosition));
    }

    // switch brand image
    if (brandImages.length === 2) {
      const [img, imgOnScroll] = brandImages;

      if (!imgOnScroll.classList.contains('d-none')) return;

      img.classList.toggle('d-none', !isTopPosition);
      imgOnScroll.classList.toggle('d-none', isTopPosition);
    }
  }
}

customElements.define('nav-bar', NavBar);
